.myPegination{
    width:100%;
   

}
.page_number{
    background: #d7e4ec;
    color: rgb(0 0 0 / 50%);
}

.SubHeader {
    margin-top: 15%;
  }
  