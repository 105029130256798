.modal {
  backdrop-filter: blur(15px);
}
.modal-content {
  box-shadow: 0 1px 7px -1px #252b3b3b;
}
.relative {
  position: relative;
}
.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.title-editor {
  background: #00c8c0 !important;
  color: white !important;
  border-bottom: 2px solid #006d71 !important;
}
.display_tooltip:hover {
  cursor: pointer;
}
.actionBox {
  height: calc(90vh - 24px);
  background: #7b7b7b;
  border: 1px dotted #cecece;
}
.btn-full-width {
  width: 100%;
}

/* upload image design */
.upload_image_area {
  height: 250px;
  position: relative;
}
.upload_image_area .load_form {
  position: relative;
  width: 100%;
  height: 200px;
  border: 4px dashed #00c8bf;
}
.upload_image_area .load_form p {
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 10;
  color: #00c8bf;
  font-family: Arial;
}
.upload_image_area .load_form input[type="file"] {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.upload_image_area .load_form button {
  margin: 0;
  color: #fff;
  background: #00c8bf;
  border: none;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border-bottom: 4px solid #03b4ab;
  transition: all 0.2s ease;
  outline: none;
}
.upload_image_area .load_form button:hover {
  background: #03b4ab;
  color: #0c5645;
}
.upload_image_area .load_form button:active {
  border: 0;
}
.iconsItem {
  width: 60px;
  height: 50px;
  display: flex;
  margin: 5px 0;
  margin-right: 5px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.iconsItem .grav_icon {
  width: 35px;
  height: 35px;
  font-size: 1.85em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 50%;
  color: #00c8bf;
  margin-bottom: 10px;
}
.customUploadBtn {
  position: relative;
}

.customUploadBtn input.upload_input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
