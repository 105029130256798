.sender {
  width: 50%;
}
.receiver {
  width: 50%;
  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/

.replyBox .modal-content {
  width: 75%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: auto;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}

.upld_img {
  position: relative;
  width: 60px;
}